import api from '@/helpers/api.js';

export default {
  async createReport(reportType, processType, talkroundId, includeAllUsers) {
    const params = {
      reportType: reportType,
      processType: processType,
      includeAllUsers: includeAllUsers
    };

    if (talkroundId) {
      params.talkroundId = talkroundId;
    }

    return await api.get(`/reporting`, params);
  },

  async downloadReport(reportType, processType, talkroundId, includeAllUsers) {
    const params = {
      reportType: reportType,
      processType: processType,
      includeAllUsers: includeAllUsers
    };

    if (talkroundId) {
      params.talkroundId = talkroundId;
    }

    const file = await api.download(`/reporting/download`, params);

    return file;
  },

  getStatusReport(reportRows, statusIndex, availableStates) {
    const data = {};

    for (let i = 0; i < availableStates.length; i++) {
      data[availableStates[i].toString()] = 0;
    }

    for (let i = 0; i < reportRows.length; i++) {
      const row = reportRows[i];
      const status = row.columns[statusIndex];
      if (data[status] !== undefined) {
        data[status]++;
      } else {
        data[status] = 1;
      }
    }

    return data;
  }
};
